import { Dispatch } from '@reduxjs/toolkit'
import WordEscape from 'components/games/word-escape/word-escape'
import Layout from 'components/layout/layout'
import Seo from 'components/seo'
import * as React from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { GameNav } from '../../components/game-nav/game-nav'
import { extraActions } from '../../store/games.slice'
import { AppState, GamesActionTypes, GamesState } from '../../types'

const mapStateToProps = (appState: AppState): GamesState => {
  return appState.games
}

const mapDispatchToWordEscapeProps = (dispatch: Dispatch<any>) => ({
  wonGame: () => dispatch({ type: GamesActionTypes.WON }),
  lostGame: () => dispatch({ type: GamesActionTypes.LOST }),
  guessedLetter: (letter: string) => dispatch({ type: GamesActionTypes.GUESS_LETTER, payload: letter }),
  getGamesById: (id: string) => dispatch(extraActions.getGameById(id)),
})

const wordEscapeConnector = connect(mapStateToProps, mapDispatchToWordEscapeProps)
const ConnectedWordEscape = wordEscapeConnector(WordEscape)

export type WordEscapeProps = ConnectedProps<typeof wordEscapeConnector>
export default class WordEscapePage extends React.Component {
  render() {
    return (
      <Layout>
        <Seo title="Word Escape" />
        <GameNav />
        <ConnectedWordEscape />
      </Layout>
    )
  }
}
