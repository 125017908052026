import './words-to-guess.scss'
import * as React from 'react'
import { alphabet } from 'src/constants/alphabet'
import { CurrentGameState } from '../../types'

type Props = {
  wordGame: CurrentGameState
}

export default class WordsToGuess extends React.Component<Props> {

  render() {
    return (
      <div className="words-to-guess-container">
        {this.props.wordGame?.guessWords.map((letter: string, index: number) => {
          const upperCaseLetter = letter.toUpperCase()
          const isLetter = alphabet.some(
            letterOfAlphabet =>
              letterOfAlphabet.toUpperCase() === upperCaseLetter
          )
          let className = 'game-character '
          if (isLetter) {
            className = className + 'letter letter-' + upperCaseLetter
          } else if (upperCaseLetter === ' ') {
            className = className + 'space'
          } else {
            className = className + 'non-letter-or-space'
          }

          const isLetterGuessed = this.props.wordGame?.lettersGuessed.includes(upperCaseLetter)

          if (isLetter && !isLetterGuessed) {
            className = className + ' hidden-letter'
          }

          if (isLetter && !isLetterGuessed) {
            return (<React.Fragment key={index}>_</React.Fragment>)
          } else {
            return letter
          }
        })}
      </div>
    )
  }
}
