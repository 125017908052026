import * as React from 'react'
import { alphabet } from '../../constants'
import Button from './button'
import { CurrentGameState } from '../../types'
import { useEffect } from 'react'
import './alphabet-buttons.scss'

export interface AlphabetButtonsProps {
  wordGame: CurrentGameState
  guessedLetter: (letter: string) => {
    type: "games/guessLetter";
    payload: string;
  }
}

/**
   * Renders buttons for each alphabet letter
   * @returns buttons for each alphabet letter 65 -90
   */
export const AlphabetButtons = (props: AlphabetButtonsProps) => {

  
  useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      if (/^[a-zA-Z]$/.test(event.key)) {
        props.guessedLetter(event.key.toUpperCase())
      }
    }
    document.addEventListener('keyup', handleKeyUp)

    return () => {
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  return (
    <div className='alphabet'>
      {alphabet.map(letter => {
        return (
          <Button
            key={letter}
            id={letter}
            disabled={props.wordGame?.lettersGuessed.some((l: string) => l === letter)}
            onClick={() => props.guessedLetter(letter)}
          >
            {letter}
          </Button>
        )
      })}
    </div>
  )
}