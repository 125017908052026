import WordsToGuess from 'components/words-to-guess/words-to-guess'
import * as React from 'react'
import { getQueryParam } from '../../../helpers'
import { WordEscapeProps } from '../../../pages/games/word-escape'
import { AlphabetButtons } from '../../buttons/alphabet-buttons'
import GameProgress from './game-progress'
import './word-escape.scss'

export default class WordEscape extends React.Component<WordEscapeProps> {
  constructor(props: WordEscapeProps) {
    super(props)
  }

  componentDidMount(): void {
    const id = getQueryParam('id')
    this.props.getGamesById(id)
  }

  /**
   * Renders word escape game
   * @returns the game in html
   */
  render() {
    const wordGame = this.props.wordGames[this.props.index]
    const content = (
      <>
        <div className="word-escape">
          <GameProgress game={wordGame} />
          <section className="game-panel">
            <WordsToGuess wordGame={wordGame} />
          </section>
          <section className="alphabet">
            <AlphabetButtons wordGame={wordGame} guessedLetter={this.props.guessedLetter}/>
          </section>
        </div>
      </>
    )

    if (this.props.isLoading) {
      return (<>Loading...</>)
    }

    if (this.props.isError) {
      return (<>oops</>)
    }

    return content
  }
}
