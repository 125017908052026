import './game-progress.scss'
import { GameStatus } from 'constants/index'
import * as React from 'react'
import { CurrentGameState } from '../../../types'

export default class GameProgress extends React.Component<{ game: CurrentGameState }> {
  render() {
    if (!this.props.game) {
      return <></>
    }
    let content = (
      <div className="game-progress">
        {this.props.game?.wrongGuesses} of {this.props.game?.maxWrongGuesses} misses
      </div>
    )

    if (this.props.game?.gameStatus === GameStatus.LOST) {
      content = <div className="game-progress lost">You Lost!</div>
    } else if (this.props.game?.gameStatus === GameStatus.WON) {
      content = <div className="game-progress won">You Won!</div>
    }

    return (
      <div className="progress-container">
        {content}
      </div>
    )
  }
}
