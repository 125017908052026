import Button from 'components/buttons/button'
import * as React from 'react'
import { gamesSlice } from '../../store/games.slice'
import { AppState } from '../../types'
import { useAppDispatch, useAppSelector } from '../../wrap-with-provider'
import './game-nav.scss'

export const GameNav = () => {

  const dispatch = useAppDispatch()
  const disablePrevious = useAppSelector((state: AppState): boolean => !!state.games.disablePrevious)
  const disableNext = useAppSelector((state: AppState): boolean => !!state.games.disableNext)

  return (
    <nav className="game-nav">
      <Button
        id="previous"
        key="previous"
        disabled={disablePrevious}
        onClick={() => dispatch(gamesSlice.actions.previous())}
        className="nav-button"
      >
        Previous
      </Button>
      <Button
        id="next"
        key="next"
        disabled={disableNext}
        onClick={() => dispatch(gamesSlice.actions.next())}
        className="nav-button"
      >
        Next
      </Button>
    </nav>
  )
}
