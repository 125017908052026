import { GameStatus } from "./constants"

export type AppState = {
  games: GamesState
}

export type GamesState = {
  disableNext?: boolean
  disablePrevious?: boolean
  index: number
  isError?: boolean
  isLoading?: boolean
  wordGames: CurrentGameState[]
}

export type CurrentGameState = {
  gameStatus: GameStatus.PLAYING | GameStatus.LOST | GameStatus.WON
  guessWords: string[]
  lettersGuessed: string[]
  lettersNotGuessed: string[]
  maxWrongGuesses: number
  wrongGuesses: number
}

export const GamesActionTypes = {
  CREATE: 'games/create',
  LOAD: 'games/load_game',
  NEXT: 'games/next',
  PREVIOUS: 'games/previous',
  WON: 'games/won',
  LOST: 'games/lost',
  GUESS_LETTER: 'games/guessLetter',
} as const
